import { Grid, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import FormInput from "../../shareComponents/form/FormInput";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import NoneDisplayInput from "../../shareComponents/input/NonDisplayForwardRefInput";
import { UserFormState } from ".";
import { mobileNumberPrefix } from "../../../utils/constants/general";
import { userGetData } from "../../../services/user";
import { useQuery } from "@tanstack/react-query";
import { REACT_QUERY_KEYS } from "../../../utils/constants/reactQueryKeys";
import BaseButton from "../../shareComponents/button/BaseButton";
import { useNavigate, useLocation } from "react-router-dom";
import { ROUTE_PATHS } from "../../../utils/constants/routes/webPaths";
import moment from "moment";

const listValue = {
  fontSize: "16px",
  fontWeight: "500",
  paddingBottom: "2px",
} as const;

const PatientSupportProgram = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const { data: userData } = useQuery(
    [REACT_QUERY_KEYS.USER_DATA],
    userGetData
  );

  const pspIndicator = userData?.data?.joinedPsp;
  const pspDate = userData?.data?.pspUpdateDate;
  const showPSPConsentPage = () => {
    navigate(ROUTE_PATHS.PSPCONSENTPAGE, { state: location.pathname });
  };
  const showDate = () => {
    return moment(pspDate).format("DD MMM, YYYY");
  };
  return (
    <Grid
      container
      justifyContent={"flex-start"}
      direction={"row"}
      alignItems={"center"}
      sx={{ mt: 1, mb: 2 }}
    >
      <Grid item xs={12}>
        <Typography variant="h6" align="left" sx={{ fontWeight: "bold" }}>
          Patient Support Program
        </Typography>
      </Grid>

      <Grid item xs={12} sm={12} md={6}>
        <Typography
          align="left"
          sx={{ fontWeight: "bold", fontSize: "16px", mt: 2, mb: 1 }}
        >
          Care For Vision Subsidy Program
        </Typography>
        {pspIndicator === true && (
          <Typography align="left" variant="body1" sx={listValue}>
            Joined: {showDate()}
          </Typography>
        )}

        {pspIndicator !== true && (
          <BaseButton sx={{ mb: 2 }} onClick={showPSPConsentPage}>
            Learn More
          </BaseButton>
        )}
      </Grid>
    </Grid>
  );
};

export default PatientSupportProgram;
