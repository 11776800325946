import React, { useEffect, useState, useMemo } from "react";
import { Grid, InputAdornment, Typography, CircularProgress } from "@mui/material";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";
import SearchIcon from "@mui/icons-material/Search";
import BaseInput from "../../input/BaseInput";
import SideDrawer from "../../side-drawer";
import { useMutation } from "@tanstack/react-query";
import { hcpAddAccess, hcpRevokeAccess } from "../../../../services/hcp";
import RevokeHCPAccessModal from "./RevokeHCPAccessModal";
import useModal from "../../../../hooks/useModal";
import { isMobile } from "react-device-detect";
import {
  HcpsByInstituteProps,
  OwnerHcpsProps,
  SharedHcpsProps,
} from "../../../../utils/types/services/hcp";
import UsersWithAccess from "./UsersWithAccess";
import { userGetData } from "../../../../services/user";
import ReactGA from "react-ga4";
import AddUserAccess from "./AddUserAccess";

// Style for overlay
const overlayStyle: React.CSSProperties = {
  position: "fixed",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(0, 0, 0, 0.5)",
  zIndex: 9999,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

const style = () => ({
  width: "100%",
} as const);

interface ShareAccessModalProps {
  isOpen: boolean;
  handleClose: () => void;
  shareDetails: HcpsByInstituteProps;
  refetchData: () => void;
}

const ShareAccessModal = (props: ShareAccessModalProps) => {
  const { isOpen, handleClose, shareDetails, refetchData } = props;
  const { data } = useQuery(["USER_DATA"], () => userGetData());
  const [searchText, setSearchText] = useState("");
  const [isAddingHcp, setIsAddingHcp] = useState(false);
  const [isFocused, setIsFocused] = useState(false);
  const [removeAccessFrom, setRemoveAccessFrom] = useState<number>(0);
  const userId: number | undefined = data?.data?.userId;
  const { t } = useTranslation();

  const {
    isOpen: isHCPDeleteModalOpen,
    closeModal: closeHCPDeleteModal,
    openModal: openHCPDeleteModal,
  } = useModal();

  const usersList: OwnerHcpsProps[] | [] = shareDetails?.owners
    ? shareDetails.owners
    : [];
  const hcpList: SharedHcpsProps[] | [] = shareDetails?.institute
    ? shareDetails.institute
    : [];

  const { mutateAsync } = useMutation(hcpAddAccess, {
    onMutate: () => {
      setIsAddingHcp(true);
    },
    onError: (error) => {
      setIsAddingHcp(false);
    },
    onSuccess: () => {
      setIsAddingHcp(false);
      refetchData();
    },
  });

  const { mutateAsync: mutateAsync2 } = useMutation(hcpRevokeAccess);

  useEffect(() => {
    if (isOpen) {
      ReactGA.send({
        hitType: "pageview",
        page: "ShareAccessModal",
      });
    }
  }, [isOpen]);

  const filteredHCPData = useMemo(() => {
    let hcpListCopy: SharedHcpsProps[] | [] = _.cloneDeep(hcpList ?? []);

    if (searchText !== "") {
      const lowercaseSearchText = searchText.trim().toLowerCase();

      hcpListCopy = hcpListCopy.filter(
        (hcp: SharedHcpsProps) =>
          (hcp.first_name ?? "").toLowerCase().includes(lowercaseSearchText) ||
          (hcp.last_name ?? "").toLowerCase().includes(lowercaseSearchText)
      );
    }
    return hcpListCopy;
  }, [hcpList, searchText]);

  const handleSearchInputChange = (text: string) => {
    setSearchText(text);
  };

  const handleAddAccess = async (id: number) => {
    if (!isAddingHcp) {
      setIsAddingHcp(true);
      try {
        const checkResult = await mutateAsync({
          pId: shareDetails.patientId ? shareDetails.patientId : -1,
          hcpId: id,
        });
        if (checkResult.success) {
          refetchData();
        }
      } catch (error) {}
        finally {
        setIsAddingHcp(false);
      }
    }
  };

  const handleDeleteHcpConfirm = async () => {
    const checkResult2 = await mutateAsync2({
      pId: shareDetails.patientId ? shareDetails.patientId : -1,
      hcpId: removeAccessFrom,
    });
    if (checkResult2.success) {
      refetchData();
      closeHCPDeleteModal();
    }
  };

  const handleRemoveAccess = (id: number) => {
    setRemoveAccessFrom(id);
    openHCPDeleteModal();
  };

  const handleDrawerClick = () => {
    setIsFocused(false);
  };

  const handleCloseDrawer = () => {
    handleClose();
  };

  return (
    <SideDrawer
      open={isOpen}
      onClose={handleCloseDrawer}
      headerText={t("shareAccessModal.header")}
      handleCloseDrawerClick={handleCloseDrawer}
      handleDrawerClick={handleDrawerClick}
    >
      <RevokeHCPAccessModal
        isOpen={isHCPDeleteModalOpen}
        toggleModal={closeHCPDeleteModal}
        handleDeleteHcpConfirm={handleDeleteHcpConfirm}
      />
      <Grid container sx={{ mt: 4, width: 1 }}>
        <BaseInput
          placeholder={t("shareAccessModal.addusers")}
          size="small"
          sx={style}
          type="search"
          onFocus={(e) => {
            setIsFocused(true);
          }}
          onClick={(e) => e.stopPropagation()}
          onChange={(e) => {
            handleSearchInputChange(e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
            disabled: isAddingHcp,
          }}
        />
        {!isFocused && (
          <UsersWithAccess
            usersList={usersList}
            userId={userId}
            handleRemoveAccess={handleRemoveAccess}
          />
        )}
        {isFocused && (
          <AddUserAccess
            handleAddAccess={handleAddAccess}
            usersList={filteredHCPData}
          />
        )}


        {isAddingHcp && (
          <div style={overlayStyle}>
            <CircularProgress color="primary" />
          </div>
        )}

        {isAddingHcp && (
          <div
            style={{
              position: "fixed",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              zIndex: 9998, 
            }}
          ></div>
        )}
      </Grid>
    </SideDrawer>
  );
};

export default ShareAccessModal;
