import {
  HcpsProps,
  HcpsByInstituteProps,
  InstituteHcpsProps,
  InstituteDetails,
  WorkingHours,
} from "../utils/types/services/hcp";
import handleRequestErr from "../utils/helperFunction/handleRequestErr";
import notificationToUser from "../utils/helperFunction/notificationToUser";
import api from "./api";
import { API_ROUTE_FULL_PATHS } from "../utils/constants/routes/apiPath";
import handleRequestFinally from "../utils/helperFunction/handleRequestFinally";
import { toast } from "react-toastify";
import i18n from "../i18n";
const STATUS_200 = 200;
const ERROR_MSG = 'Retry Will Apply';

export const getHcpsData = async (): Promise<{
  success: boolean;
  status?: number;
  data?: HcpsProps[];
}> => {
  let getHcpResult = null;
  try {
    getHcpResult = await api.get(API_ROUTE_FULL_PATHS.hcpGetHcps);
    if (getHcpResult.status === STATUS_200) {
      return {
        success: true,
        data: getHcpResult.data.data,
      };
    }

    throw new Error(ERROR_MSG);
  } catch (err) {
    handleRequestErr(err);
    throw err;
  } finally {
    handleRequestFinally({ result: getHcpResult });
  }
};

export const getItAdminData = async (): Promise<{
  success: boolean;
  status?: number;
  data?: HcpsProps[];
}> => {
  let getItAdminResult = null;
  try {
    getItAdminResult = await api.get(API_ROUTE_FULL_PATHS.getItAdmin);
    if (getItAdminResult.status === STATUS_200) {
      return {
        success: true,
        data: getItAdminResult.data.data,
      };
    }

    throw new Error(ERROR_MSG);
  } catch (err) {
    handleRequestErr(err);
    throw err;
  } finally {
    handleRequestFinally({ result: getItAdminResult });
  }
};

export const deleteHcp = async (
  hcpId: number
): Promise<{
  success: boolean;
  status?: number;
}> => {
  let deleteHcpResult = null;
  try {
    deleteHcpResult = await api.post(API_ROUTE_FULL_PATHS.hcpDeleteHcps, {
      hcpId,
    });

    if (deleteHcpResult.status === STATUS_200) {
      toast.success(
        i18n.t("adminControlPage.deleteModal.deleteSuccess") as string
      );
      return {
        success: true,
      };
    }

    notificationToUser({ msg: deleteHcpResult.data.msg });

    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: deleteHcpResult });
  }
};

export const downloadHcpLog = async ({
  hcpId,
  fromDate,
  toDate,
}: {
  hcpId: number;
  fromDate: string | null;
  toDate: string | null;
}): Promise<{
  success: boolean;
  status?: number;
  data?: {
    url: string;
    method: string;
    type: string;
    user_id: number | null;
    status_code: number;
    ip: string | null;
    parameter: string;
    create_time: string;
    remark: string | null;
    description: string | null;
  }[];
}> => {
  let downloadHcpLogResult = null;
  try {
    downloadHcpLogResult = await api.get(
      API_ROUTE_FULL_PATHS.hcpDownloadHcpLog,
      {
        params: {
          hcpId,
          fromDate,
          toDate,
        },
      }
    );

    if (downloadHcpLogResult.status === STATUS_200)
      return {
        success: true,
        data: downloadHcpLogResult.data.data,
      };

    notificationToUser({ msg: downloadHcpLogResult.data.msg });

    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: downloadHcpLogResult });
  }
};

export const getShareHcpData = async ({
  pId,
}: {
  pId: number | undefined;
}): Promise<{
  success: boolean;
  status?: number;
  data?: HcpsByInstituteProps;
}> => {
  let confirmResult = null;
  try {
    confirmResult = await api.post(API_ROUTE_FULL_PATHS.getHcpByInstitute, {
      pId,
    });

    if (confirmResult.status === STATUS_200)
      return {
        success: true,
        data: confirmResult.data.data,
      };

    notificationToUser({ msg: confirmResult.data.msg });

    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: confirmResult });
  }
};

export const hcpAddAccess = async ({
  pId,
  hcpId,
}: {
  pId: number;
  hcpId: number;
}): Promise<{
  success: boolean;
  status?: number;
}> => {
  let confirmResult = null;
  try {
    confirmResult = await api.post(API_ROUTE_FULL_PATHS.addEditor, {
      pId,
      hcpId,
    });

    if (confirmResult.status === STATUS_200) {
      toast.success(i18n.t("message.toast.addAccessSuccess") as string);
      return {
        success: true,
        status: confirmResult.status,
      };
    }

    notificationToUser({ msg: confirmResult.data.msg });

    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: confirmResult });
  }
};

export const hcpRevokeAccess = async ({
  pId,
  hcpId,
}: {
  pId: number;
  hcpId: number;
}): Promise<{
  success: boolean;
  status?: number;
}> => {
  let confirmResult = null;
  try {
    confirmResult = await api.post(API_ROUTE_FULL_PATHS.revokeEditAccess, {
      pId,
      hcpId,
    });

    if (confirmResult.status === STATUS_200) {
      toast.success(i18n.t("message.toast.revokeAccessSuccess") as string);
      return {
        success: true,
        status: confirmResult.status,
      };
    }

    notificationToUser({ msg: confirmResult.data.msg });

    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: confirmResult });
  }
};

export const addHCP = async (
  hcpData: InstituteHcpsProps
): Promise<{ success: boolean; status?: number }> => {
  let addHCPResult = null;
  try {
    const {
      firstName,
      lastName,
      preferredName,
      email,
      mobile,
      mobilePrefix,
      instituteDetails,
    } = hcpData;

    const modifiedHCPData = {
      firstName,
      lastName,
      preferredName,
      email,
      mobile: mobile !== "" ? mobilePrefix + mobile : "",
      instituteDetails: instituteDetails.map((institute) => {
        // If instituteName is falsy, return an empty object
        if (institute.instituteName === "") {
          return {
            instituteName: "",
            instituteType: "",
            instituteAddress: "",
            instituteDistrict: "-",
            instituteMobile: "",
            Monday: "",
            Tuesday: "",
            Wednesday: "",
            Thursday: "",
            Friday: "",
            Saturday: "",
            Sunday: "",
            hideHours: false,
          };
        }

        // Format working hours for each day
        const formatWorkingHours = (day: {
          isOpen: boolean;
          start: string;
          end: string;
        }) => {
          if (day.isOpen) {
            return `${day.start} to ${day.end}`;
          } else {
            return "Closed";
          }
        };

        // Construct the object for each institute with formatted working hours
        return {
          instituteType: institute.instituteType.toString(),
          instituteName: institute.instituteName,
          instituteAddress: institute.instituteAddress || "",
          instituteDistrict: institute.instituteDistrict || "-",
          instituteMobile:
            institute.instituteMobile !== ""
              ? institute.instituteMobilePrefix + institute.instituteMobile
              : "",
          hideHours: institute.hideHours,
          Monday: formatWorkingHours(institute.Monday),
          Tuesday: formatWorkingHours(institute.Tuesday),
          Wednesday: formatWorkingHours(institute.Wednesday),
          Thursday: formatWorkingHours(institute.Thursday),
          Friday: formatWorkingHours(institute.Friday),
          Saturday: formatWorkingHours(institute.Saturday),
          Sunday: formatWorkingHours(institute.Sunday),
        };
      }),
    };

    addHCPResult = await api.post(API_ROUTE_FULL_PATHS.addHCP, modifiedHCPData);

    if (addHCPResult.status === STATUS_200) {
      //toast.success(i18n.t("message.toast.addSuccess") as string);
      return {
        success: true,
        status: STATUS_200,
      };
    }
    notificationToUser({ msg: addHCPResult.data.msg });

    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: addHCPResult });
  }
};

export const getHcpsCreationlist = async (): Promise<{
  success: boolean;
  status?: number;
  data?: InstituteHcpsProps[];
}> => {
  let getHcpResult = null;
  try {
    getHcpResult = await api.get(API_ROUTE_FULL_PATHS.getAllHCp);

    if (getHcpResult.status === STATUS_200) {
      const hcpData = getHcpResult.data;

      const transformedData: InstituteHcpsProps[] = hcpData.map((hcp: any) => {
        const instituteDetails: InstituteDetails[] = hcp.instituteDetails.map(
          (detail: any) => {
            const workingHours: { [key: string]: WorkingHours } = {
              Monday: {
                isOpen: detail.Monday === "Closed" ? false : true,
                start: detail.Monday,
                end: detail.Monday,
              },
              Tuesday: {
                isOpen: detail.Tuesday === "Closed" ? false : true,
                start: detail.Tuesday,
                end: detail.Tuesday,
              },
              Wednesday: {
                isOpen: detail.Wednesday === "Closed" ? false : true,
                start: detail.Wednesday,
                end: detail.Wednesday,
              },
              Thursday: {
                isOpen: detail.Thursday === "Closed" ? false : true,
                start: detail.Thursday,
                end: detail.Thursday,
              },
              Friday: {
                isOpen: detail.Friday === "Closed" ? false : true,
                start: detail.Friday,
                end: detail.Friday,
              },
              Saturday: {
                isOpen: detail.Saturday === "Closed" ? false : true,
                start: detail.Saturday,
                end: detail.Saturday,
              },
              Sunday: {
                isOpen: detail.Sunday === "Closed" ? false : true,
                start: detail.Sunday,
                end: detail.Sunday,
              },
            };
            return {
              instituteName: detail.instituteName,
              instituteType: detail.instituteType,
              instituteAddress: detail.instituteAddress,
              workingHours: workingHours,
            };
          }
        );

        return {
          firstName: hcp.firstName,
          lastName: hcp.lastName,
          hcpId: hcp.hcpId !== null ? hcp.hcpId : "",
          email: hcp.email,
          mobile: hcp.mobile,
          preferredName: hcp.preferredName,
          instituteDetails: instituteDetails,
        };
      });

      return {
        success: true,
        data: transformedData,
      };
    }
    throw new Error(ERROR_MSG);
  } catch (err) {
    handleRequestErr(err);
    throw err;
  } finally {
    handleRequestFinally({ result: getHcpResult });
  }
};

export const EditHCP = async (
  hcpData: InstituteHcpsProps
): Promise<{ success: boolean; status?: number }> => {
  let editHCPResult = null;
  try {
    const {
      firstName,
      lastName,
      preferredName,
      email,
      mobile,
      mobilePrefix,
      instituteDetails,
    } = hcpData;

    const modifiedHCPData = {
      firstName,
      lastName,
      preferredName,
      email,
      mobile: mobile !== "" ? mobilePrefix + mobile : "",
      instituteDetails: instituteDetails.map((institute, index) => {
        if (!institute.instituteName) {
          return {
            instituteName: "",
            instituteType: "",
            instituteAddress: "",
            instituteDistrict: "-",
            instituteMobile: "",
            Monday: "",
            Tuesday: "",
            Wednesday: "",
            Thursday: "",
            Friday: "",
            Saturday: "",
            Sunday: "",
            hideHours: false,
          };
        }
        const formatWorkingHours = (day: {
          isOpen: boolean;
          start: string;
          end: string;
        }) => {
          if (day.isOpen) {
            return `${day.start} to ${day.end}`;
          } else {
            return "Closed";
          }
        };
        return {
          instituteType: institute.instituteType.toString(),
          instituteName: institute.instituteName,
          instituteAddress: institute.instituteAddress || "",
          instituteDistrict: institute.instituteDistrict || "-",
          instituteMobile:
            institute.instituteMobile !== ""
              ? institute.instituteMobilePrefix + institute.instituteMobile
              : "",
          Monday: formatWorkingHours(institute.Monday),
          Tuesday: formatWorkingHours(institute.Tuesday),
          Wednesday: formatWorkingHours(institute.Wednesday),
          Thursday: formatWorkingHours(institute.Thursday),
          Friday: formatWorkingHours(institute.Friday),
          Saturday: formatWorkingHours(institute.Saturday),
          Sunday: formatWorkingHours(institute.Sunday),
          hideHours: institute.hideHours,
        };
      }),
    };

    editHCPResult = await api.post(
      API_ROUTE_FULL_PATHS.userUpdateHcpInstitute,
      modifiedHCPData
    );

    if (editHCPResult.status === STATUS_200) {
      return {
        success: true,
        status: STATUS_200,
      };
    }
    notificationToUser({ msg: editHCPResult.data.msg });

    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: editHCPResult });
  }
};

export const getInstituteInfohcp = async (email: string): Promise<any> => {
  let sendRegisterCodeResult = null;
  try {
    sendRegisterCodeResult = await api.post(
      API_ROUTE_FULL_PATHS.userGetInstituteInfo,
      {
        email,
      }
    );

    if (sendRegisterCodeResult.status === STATUS_200)
      return {
        success: true,
        data: sendRegisterCodeResult.data.data,
      };
    notificationToUser({ msg: sendRegisterCodeResult.data.msg });
    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: sendRegisterCodeResult });
  }
};

export const getemailValidate = async (email: string): Promise<any> => {
  let sendRegisterCodeResult = null;
  try {
    sendRegisterCodeResult = await api.post(
      API_ROUTE_FULL_PATHS.userGetInstituteInfo,
      {
        email,
      }
    );

    if (sendRegisterCodeResult.status === STATUS_200)
      return {
        success: true,
        data: sendRegisterCodeResult.data.data,
      };
    notificationToUser({ msg: sendRegisterCodeResult.data.msg });
    return {
      success: false,
    };
  } catch (err) {
    return;
  } finally {
    handleRequestFinally({ result: sendRegisterCodeResult });
  }
};
