import { Typography, Grid, Box } from "@mui/material";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import SecondaryButton from "../shareComponents/button/SecondaryButton";
import BaseButton from "../shareComponents/button/BaseButton";
import CancelConfirmModal from "./PSPConsentPage/CancelConfirmModal";
import { useNavigate, useLocation } from "react-router-dom";
import { ROUTE_PATHS } from "../../utils/constants/routes/webPaths";

interface PspIndicatorProps {
  pspIndicator: boolean | null | undefined;
  handleOKClick?: () => void;
}

const PspIndicator = (props: PspIndicatorProps) => {
  const { pspIndicator, handleOKClick } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [showPspToHCP, setShowPspToHCP] = useState(
    sessionStorage.getItem("showPspToHCP")
  );

  const showPSPConsentPage = () => {
    navigate(ROUTE_PATHS.PSPCONSENTPAGE, {
      state: { history: location.pathname },
    });
  };

  const handleOK = () => {
    sessionStorage.setItem("showPspToHCP", "false");
    setShowPspToHCP("false");
    if (handleOKClick) handleOKClick();
  };

  return (
    <Grid item>
      {showPspToHCP === "true" && (
        <Grid item xs={12} sx={{ backgroundColor: "#D4FFE3", p: 3 }}>
          {pspIndicator === null && (
            <Grid>
              <Typography
                variant="h6"
                sx={{ color: "#00B458", fontWeight: "700" }}
              >
                Care For Vision Subsidy Program
              </Typography>
              <Grid item xs={12} sm={12} md={8} xl={8}>
                <Typography variant="body1">
                  By joining the program, your patient will be able to benefit
                  from financial support for Vabysmo injection in the private
                  sector. 
                </Typography>
              </Grid>
              <Grid item sx={{ mt: 2, mb: 1 }}>
                <BaseButton sx={{ mr: 3 }} onClick={showPSPConsentPage}>
                  Learn More
                </BaseButton>
              </Grid>
            </Grid>
          )}
          {pspIndicator === false && (
            <Grid>
              <Typography
                variant="h6"
                sx={{ color: "#00B458", fontWeight: "700" }}
              >
                You Declined Care For Vision Subsidy Program
              </Typography>
              <Grid item xs={12} sm={12} md={8} xl={8}>
                <Typography variant="body1">
                  The option to participate remains available on the Profile
                  Setting page. Thank you!
                </Typography>
              </Grid>
              <Grid item sx={{ mt: 2, mb: 1 }}>
                <BaseButton sx={{ mr: 3 }} onClick={handleOK}>
                  OK
                </BaseButton>
              </Grid>
            </Grid>
          )}
        </Grid>
      )}
    </Grid>
  );
};

export default PspIndicator;
