import _ from "lodash";
import {
  Box,
  FormLabel,
  Grid,
  Divider,
  IconButton,
  MenuItem,
} from "@mui/material";
import PhoneInputWithCountry from "react-phone-number-input/react-hook-form";
import { useTranslation } from "react-i18next";
import { useFormContext } from "react-hook-form";
import {
  PatientDetails,
  PatientFormState,
} from "../../../../../utils/types/services/patients";
import NoneDisplayInput from "../../../input/NonDisplayForwardRefInput";
import { mobileNumberPrefix } from "../../../../../utils/constants/general";
import FormInput from "../../../form/FormInput";
import { PatientModalMode } from "../../../../../utils/types/ui/patientModalMode";
import { useCallback, useEffect, useMemo, Fragment, useState } from "react";
import BaseButton from "../../../button/BaseButton";
import { toast } from "react-toastify";
import ReactGA from "react-ga4";
import { useMutation } from "@tanstack/react-query";
import { checkCGConsent, sendSMSToCG } from "../../../../../services/patient";

import VerifiedIcon from "@mui/icons-material/Verified";
import { timeLimits } from "../../../../../utils/constants/timeRelated";
import SmsIcon from "../../../../../assets/images/Innovision/sms_icon.png";

interface CaregiverDetailsFormProps {
  patient?: PatientDetails;
  mode: PatientModalMode;
}

const CaregiverDetailsForm = (props: CaregiverDetailsFormProps) => {
  const { patient } = props;
  const { t } = useTranslation();

  const { getValues, control, trigger, formState } =
    useFormContext<PatientFormState>();
  const { mutateAsync, isLoading } = useMutation(sendSMSToCG);
  const { mutateAsync: mutateAsync2, isLoading: isLoading2 } =
    useMutation(checkCGConsent);
  const [hasCgConsent1, setHasCgConsent1] = useState<boolean>(false);
  const [hasCgConsent2, setHasCgConsent2] = useState<boolean>(false);
  const [isCg, setIsCg] = useState<string>("cg1");
  const [resendSeconds, setResendSeconds] = useState<number>(0);
  const [resendSeconds2, setResendSeconds2] = useState<number>(0);

  const NUM_1000 = 1000;

  const onClickSendSMS2CareGiver1 = useCallback(async () => {
    const triggerResult = await trigger(["caregiver1mobile"]);
    setIsCg("cg1");
    const [caregiver1mobile, caregiver1Prefix] = getValues([
      "caregiver1mobile",
      "caregiver1Prefix",
    ]);

    if (!triggerResult) {
      return;
    }

    const result1 = await mutateAsync({
      mobileNumber: caregiver1Prefix + caregiver1mobile,
      patientId: patient?.id,
    });
    if (result1?.success === true) {
      setResendSeconds(timeLimits.twoFactorAuthResendSeconds);
      const countDownInterval1 = setInterval(() => {
        setResendSeconds((val) => {
          --val;

          return Math.max(val, 0);
        });
      }, NUM_1000);

      return () => clearInterval(countDownInterval1);
    }
  }, [mutateAsync, getValues, t, trigger, patient, formState]);
  const onClickSendSMS2CareGiver2 = useCallback(async () => {
    const triggerResult = await trigger(["caregiver2mobile"]);
    setIsCg("cg2");
    const [caregiver2mobile, caregiver2Prefix] = getValues([
      "caregiver2mobile",
      "caregiver2Prefix",
    ]);

    if (!triggerResult) {
      return;
    }

    const result2 = await mutateAsync({
      mobileNumber: caregiver2Prefix + caregiver2mobile,
      patientId: patient?.id,
    });

    if (result2?.success === true) {
      setResendSeconds2(timeLimits.twoFactorAuthResendSeconds);
      const countDownInterval2 = setInterval(() => {
        setResendSeconds2((val) => {
          --val;

          return Math.max(val, 0);
        });
      }, NUM_1000);

      return () => clearInterval(countDownInterval2);
    }
  }, [mutateAsync, getValues, t, trigger, patient, formState]);

  const onClickVerifyConsent = useCallback(async () => {
    const triggerResult = await trigger(["caregiver1mobile"]);
    setIsCg("cg1");
    if (!triggerResult) {
      const toastMsg = t("Enter Mobile");
      toast.error(toastMsg);
      return;
    }
    const mobileNumber =
      getValues("caregiver1Prefix") + getValues("caregiver1mobile");
    const checkResult = await mutateAsync2({
      mobileNumber,
      patientId: patient?.id,
    });
    if (checkResult.data?.consent) setHasCgConsent1(true);

  }, [getValues, trigger, t, patient]);

  const onClickVerifyConsent2 = useCallback(async () => {
    setIsCg("cg2");
    const triggerResult = await trigger(["caregiver2mobile"]);
    if (!triggerResult) {
      const toastMsg = t("Enter Mobile");
      toast.error(toastMsg);
      return;
    }
    const mobileNumber =
      getValues("caregiver2Prefix") + getValues("caregiver2mobile");
    const checkResult = await mutateAsync2({
      mobileNumber,
      patientId: patient?.id,
    });
    if (checkResult.data?.consent) setHasCgConsent2(true);

  }, [getValues, trigger, t, patient]);

  return (
    <Box>
      <Box>
        <FormLabel>Caregiver 1</FormLabel>

        <Grid item xs={12} container direction="row">
          <Grid item xs={3}>
            <FormInput
              select
              control={control}
              size="small"
              name={"caregiver1Prefix"}
              sx={{ width: 1 }}
            >
              {[
                { id: "HK", displayText: "+852" },
                { id: "MO", displayText: "+853" },
              ].map(({ id, displayText }) => (
                <MenuItem key={id} value={displayText}>
                  {displayText}
                </MenuItem>
              ))}
            </FormInput>
          </Grid>
          <Grid item xs={9}>
            <FormInput
              control={control}
              name="caregiver1mobile"
              size="small"
              placeholder="Type here..."
              variant="outlined"
              sx={{ width: 1 }}
              type="tel-local"
            />
          </Grid>
        </Grid>
        {getValues("caregiver1mobile") !== "" &&
          formState.errors["caregiver1mobile"] === undefined && (
            <Fragment>
              <BaseButton
                startIcon={
                  <img
                    src={SmsIcon}
                    className="imgCrisp"
                    style={{ width: "24px", height: "24px" }}
                  />
                }
                onClick={() => onClickSendSMS2CareGiver1()}
                disabled={
                  hasCgConsent1 ||
                  resendSeconds !== 0 ||
                  (isCg === "cg1" && (isLoading || isLoading2))
                }
                sx={{
                  marginLeft: 1,
                }}
              >
                {hasCgConsent1
                  ? t("singlePharse.verified")
                  : `${t("patientModal.addPatient.sendCareGiverAppLink")}
                  ${resendSeconds === 0 ? "" : `(${resendSeconds}s)`}`}
              </BaseButton>

              <IconButton
                onClick={onClickVerifyConsent}
                disabled={
                  hasCgConsent1 ||
                  resendSeconds !== 0 ||
                  (isCg === "cg1" && (isLoading || isLoading2))
                }
              >
                {hasCgConsent1 && <VerifiedIcon color="success" />}
              </IconButton>
            </Fragment>
          )}
      </Box>
      <Grid item xs={12}>
        <Divider sx={{ mt: 4, mb: 4 }} />
      </Grid>
      <Box>
        <FormLabel>Caregiver 2</FormLabel>
        <Grid item xs={12} container direction="row">
          <Grid item xs={3}>
            <FormInput
              select
              control={control}
              size="small"
              name={"caregiver2Prefix"}
              sx={{ width: 1 }}
            >
              {[
                { id: "HK", displayText: "+852" },
                { id: "MO", displayText: "+853" },
              ].map(({ id, displayText }) => (
                <MenuItem key={id} value={displayText}>
                  {displayText}
                </MenuItem>
              ))}
            </FormInput>
          </Grid>
          <Grid item xs={9}>
            <FormInput
              control={control}
              name="caregiver2mobile"
              size="small"
              placeholder="Type here..."
              variant="outlined"
              sx={{ width: 1 }}
              type="tel-local"
            />
          </Grid>
        </Grid>
        {getValues("caregiver2mobile") !== "" &&
          formState.errors["caregiver2mobile"] === undefined && (
            <Fragment>
              <BaseButton
                startIcon={
                  <img
                    src={SmsIcon}
                    className="imgCrisp"
                    style={{ width: "24px", height: "24px" }}
                  />
                }
                onClick={() => onClickSendSMS2CareGiver2()}
                disabled={
                  hasCgConsent2 ||
                  resendSeconds2 !== 0 ||
                  (isCg === "cg2" && (isLoading || isLoading2))
                }
                sx={{
                  marginLeft: 1,
                }}
              >
                {hasCgConsent2
                  ? t("singlePharse.verified")
                  : `${t("patientModal.addPatient.sendCareGiverAppLink")}${
                      resendSeconds2 === 0 ? "" : `(${resendSeconds2}s)`
                    }`}
              </BaseButton>

              <IconButton
                onClick={onClickVerifyConsent2}
                disabled={
                  hasCgConsent2 ||
                  resendSeconds2 !== 0 ||
                  (isCg === "cg2" && (isLoading || isLoading2))
                }
              >
                {hasCgConsent1 && <VerifiedIcon color="success" />}
              </IconButton>
            </Fragment>
          )}
      </Box>
      <Grid item xs={12}>
        <Divider sx={{ mt: 4, mb: 4 }} />
      </Grid>
    </Box>
  );
};

export default CaregiverDetailsForm;
