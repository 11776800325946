import { Box, Grid } from "@mui/material";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import useModal from "../../hooks/useModal";
import PatientModal from "../shareComponents/modal/PatientModal";
import ContactsModal from "../shareComponents/modal/ContactsModal";
import PatientDetailPersonalInfo from "./PatientDetailPersonalInfo";
import ShareToPatient from "./ShareToPatient";
import TreatmentInfoContainer from "./TreatmentInfoContainer";
import TreatmentInfoMobileContainer from "./TreatmentInfoMobileContainer";

import TreatmentRecordModal from "./TreatmentRecordModal";
import AEModal from "./AEModal";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  getPatientDetailsData,
  updateTreatmentPhase,
  patientEditCheck,
  updateEditCheck,
} from "../../services/patient";
import { REACT_QUERY_KEYS } from "../../utils/constants/reactQueryKeys";
import { getAllDBConstants } from "../../services/dbConstants";
import { Eye } from "../../utils/constants/general";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import toastIds from "../../utils/constants/toastIds";
import { AEDataProps } from "../../services/treatmentRecord";
import { userGetData } from "../../services/user";
import ShareAccessModal from "../shareComponents/modal/ShareAccessModal";
import { getShareHcpData } from "../../services/hcp";
import { eyeStatus } from "../../utils/types/services/patients";
import { HcpsByInstituteProps } from "../../utils/types/services/hcp";
import { Theme } from "@mui/material/styles";
import dayjs from "dayjs";

const header_styles = (theme: Theme) =>
  ({
    display: "flex",
    gap: 2,
    height: "fit-content",
    flexDirection: "row",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  } as const);

const PatientDetailPage = () => {
  const { t } = useTranslation();
  const { id } = useParams();

  useEffect(() => {
    document.title = "InnoVision - Patient Details";
  }, []);

  const { data: userData } = useQuery(
    [REACT_QUERY_KEYS.USER_DATA],
    userGetData
  );

  const userId = userData?.data?.userId;

  const { data, isLoading } = useQuery(
    [REACT_QUERY_KEYS.PATIENT, +id!],
    () => getPatientDetailsData(+id!),
    {
      retry: true,
      refetchOnMount: "always",
    }
  );

  const { data: dbConstants } = useQuery(
    [REACT_QUERY_KEYS.DB_CONSTANTS],
    getAllDBConstants
  );

  const { mutateAsync } = useMutation(updateTreatmentPhase);
  const { mutateAsync: mutateAsync2 } = useMutation(getShareHcpData);
  const { mutateAsync: mutateAsync3 } = useMutation(patientEditCheck);
  const { mutateAsync: mutateAsync4 } = useMutation(updateEditCheck);

  const treatmentPhaseTypes = dbConstants?.data?.treatmentPhaseTypes ?? [];

  const patient = data?.data ?? null;

  const [treatmentMethods, setTreatmentMethods] = useState<[number, number]>([
    treatmentPhaseTypes[0].id,
    treatmentPhaseTypes[0].id,
  ]);

  const [treatmentStatus, setTreatmentStatus] = useState<
    [keyof typeof eyeStatus | null, keyof typeof eyeStatus | null]
  >(["new", "new"]);

  const [treatmentDates, setTreatmentDates] = useState<[any, any]>([[], []]);

  useEffect(() => {
    if (patient === null) return;
    setTreatmentMethods([
      patient.leftEyeDetails.treatmentPhaseIdSelected ??
        treatmentPhaseTypes[0].id,
      patient.rightEyeDetails.treatmentPhaseIdSelected ??
        treatmentPhaseTypes[0].id,
    ]);
    setTreatmentStatus([
      patient.leftEyeDetails.status ?? "new",
      patient.rightEyeDetails.status ?? "new",
    ]);
    const rightDates: any = [];
    const leftDates: any = [];
    patient.rightEyeTreatmentRecords.map((rec) => {
      rightDates.push({
        id: rec.id,
        date: dayjs(rec.date).format("DD MMM YYYY"),
        drug: rec.drugId,
      });
    });
    patient.leftEyeTreatmentRecords.map((rec) => {
      leftDates.push({
        id: rec.id,
        date: dayjs(rec.date).format("DD MMM YYYY"),
        drug: rec.drugId,
      });
    });
    setTreatmentDates([rightDates, leftDates]);
  }, [patient]);

  const [treatmentId, setTreatmentId] = useState<number | null>(null);

  const [currentEye, setCurrentEye] = useState<Eye>(Eye.LEFT);

  const [aeData, setAEData] = useState<AEDataProps | null>(null);
  const [shareDetails, setShareDetails] = useState<HcpsByInstituteProps>({
    patientId: -1,
    institute: [],
    owners: [],
  });
  const [details, setDetails] = useState<{ pId: number | undefined }>();

  const handleChangeTreatmentMethods = useCallback(
    async (eye: Eye, value: number) => {
      setTreatmentMethods((val) => {
        val[eye] = value;
        return [...val];
      });
      const eyeId =
        eye === Eye.LEFT
          ? patient!.leftEyeDetails.id
          : patient!.rightEyeDetails.id;
      await mutateAsync({ eyeId, treatmentPhaseId: value });
    },
    [patient]
  );

  const handleChangeTreatmentStatus = useCallback(
    async (eye: Eye, value: keyof typeof eyeStatus | null) => {
      setTreatmentStatus((val) => {
        val[eye] = value;
        return [...val];
      });
    },
    [patient]
  );

  const treatmentRecord = useMemo(() => {
    if (treatmentId === null) return null;
    const leftEyeTreatmentRecords = data?.data?.leftEyeTreatmentRecords ?? [];
    const rightEyeTreatmentRecords = data?.data?.rightEyeTreatmentRecords ?? [];
    const treatmentRecords = leftEyeTreatmentRecords.concat(
      rightEyeTreatmentRecords
    );
    return treatmentRecords.find((e) => e.id === treatmentId) ?? null;
  }, [treatmentId, data]);

  const {
    isOpen: isPatientModalOpen,
    closeModal: closePatientModal,
    openModal: openPatientModal,
  } = useModal();

  const {
    isOpen: isContactsModalOpen,
    closeModal: closeContactsModal,
    openModal: openContactsModal,
  } = useModal();

  const {
    isOpen: isAEModalOpen,
    closeModal: closeAEModal,
    openModal: openAEModal,
  } = useModal();

  const {
    isOpen: isTreatmentRecordModalOpen,
    closeModal: closeTreatmentRecordModal,
    openModal: openTreatmentRecordModal,
  } = useModal();

  const {
    isOpen: isShareAccessDrawerOpen,
    closeModal: closeShareAccessDrawer,
    openModal: openShareDrawer,
  } = useModal();

  const handleOpenAEModal = useCallback((o_data: AEDataProps) => {
    setAEData(o_data);
    openAEModal();
  }, []);

  const handleCloseAEModal = useCallback(() => {
    setAEData(null);
    closeAEModal();
  }, []);

  const refetchData = async () => {
    const checkResult = await mutateAsync2({
      pId: details ? details.pId : undefined,
    });
    if (checkResult?.data) {
      setShareDetails({
        ...checkResult.data,
        patientId: details ? details.pId : undefined,
      });
    }
  };

  const handleShareClick = useCallback(async () => {
    const pId = patient?.id;
    setDetails({ pId });
    const checkResult = await mutateAsync2({
      pId,
    });
    if (checkResult?.data) {
      setShareDetails({ ...checkResult.data, patientId: pId });
      openShareDrawer();
    }
  }, [patient]);

  const handleAddTreatmentRecordClick = async (eye: Eye) => {
    const editStatus = await checkEditStatus();
    if (editStatus) {
      handleAddTreatmentRecordClickConfirm(eye);
    } else {
      const toastMsg = "Some one is editing the details.Please wait";
      toast.error(toastMsg, {
        toastId: toastIds.ADD_TREATMENT,
      });
    }
  };

  const handleAddTreatmentRecordClickConfirm = useCallback(
    async (eye: Eye) => {
      const eyeDiseaseTypeId =
        eye === Eye.LEFT
          ? patient?.leftEyeDetails.diseaseTypeId
          : patient?.rightEyeDetails.diseaseTypeId;

      if (eyeDiseaseTypeId === undefined || eyeDiseaseTypeId === null) {
        const toastMsg = t("message.toast.selectDiseaseType");
        toast.info(toastMsg, {
          toastId: toastIds.ADD_TREATMENT,
        });
      }

      setTreatmentId(null);
      setCurrentEye(eye);
      openTreatmentRecordModal();
    },
    [openTreatmentRecordModal, patient, t]
  );

  const checkEditStatus = async () => {
    const isEditableData = await mutateAsync3({
      patientId: patient ? patient.id : 0,
    });
    return isEditableData.success ? isEditableData.data : false;
  };

  const handleCloseTreatmentRecordModal = async () => {
    closeTreatmentRecordModal();
    await mutateAsync4({
      patientId: patient ? patient.id : 0,
    });
  };

  const onEditTreatmentInfoClick = async (e_id: number) => {
    const editStatus = await checkEditStatus();
    if (editStatus) {
      onEditTreatmentInfoClickAfterCheck(e_id);
    } else {
      const toastMsg = "Some one is editing the details.Please wait";
      toast.error(toastMsg, {
        toastId: toastIds.ADD_TREATMENT,
      });
    }
  };

  const onEditTreatmentInfoClickAfterCheck = useCallback(
    async (edit_id: number) => {
      let eyeDiseaseTypeId = null;
      const leftEyeTreatmentRecords = data?.data?.leftEyeTreatmentRecords ?? [];
      if (leftEyeTreatmentRecords.find((e) => e.id === edit_id)) {
        eyeDiseaseTypeId = patient?.leftEyeDetails.diseaseTypeId;
        setCurrentEye(Eye.LEFT);
      }

      const rightEyeTreatmentRecords =
        data?.data?.rightEyeTreatmentRecords ?? [];
      if (rightEyeTreatmentRecords.find((e) => e.id === edit_id)) {
        eyeDiseaseTypeId = patient?.rightEyeDetails.diseaseTypeId;
        setCurrentEye(Eye.RIGHT);
      }

      if (eyeDiseaseTypeId === undefined || eyeDiseaseTypeId === null) {
        const toastMsg = t("message.toast.selectDiseaseType");
        toast.info(toastMsg, {
          toastId: toastIds.ADD_TREATMENT,
        });
        return;
      }

      setTreatmentId(edit_id);
      openTreatmentRecordModal();
    },
    [openTreatmentRecordModal, data, patient, t]
  );

  if (!patient || isLoading) return null;

  return (
    <Box
      sx={{
        mx: 2,
        mt: 1,
        height: "calc(100% - 100px)",
        display: "flex",
        flexDirection: "column",
        gap: 3,
      }}
    >
      <PatientModal
        isOpen={isPatientModalOpen}
        handleClose={closePatientModal}
        patient={patient}
      />
      <ContactsModal
        isOpen={isContactsModalOpen}
        handleClose={closeContactsModal}
        patient={patient}
      />
      <AEModal
        modalData={aeData}
        open={isAEModalOpen}
        handleClose={handleCloseAEModal}
      />

      <ShareAccessModal
        isOpen={isShareAccessDrawerOpen}
        handleClose={closeShareAccessDrawer}
        shareDetails={shareDetails}
        refetchData={refetchData}
      />

      <TreatmentRecordModal
        isOpen={isTreatmentRecordModalOpen}
        treatmentDates={treatmentDates}
        handleClose={handleCloseTreatmentRecordModal}
        treatmentRecord={treatmentRecord}
        treatmentMethod={treatmentMethods[currentEye]}
        patientId={patient.id}
        currentEye={currentEye}
        handleOpenAEModal={handleOpenAEModal}
      />

      <Box sx={header_styles}>
        <PatientDetailPersonalInfo
          patient={patient}
          handleEditPatientClick={openPatientModal}
          handleViewContacts={openContactsModal}
          handleShareClick={handleShareClick}
          isOwner={userId === patient.Owner}
        />
        <Grid
          item
          sm
          md
          sx={{ display: { xs: "none", sm: "block", md: "block" } }}
        >
          <ShareToPatient patient={patient} />
        </Grid>
      </Box>
      <Grid item xs sx={{ display: { xs: "block", sm: "none", md: "none" } }}>
        <TreatmentInfoMobileContainer
          leftEyeTreatmentRecords={patient.leftEyeTreatmentRecords}
          rightEyeTreatmentRecords={patient.rightEyeTreatmentRecords}
          leftEyeDetails={patient.leftEyeDetails}
          rightEyeDetails={patient.rightEyeDetails}
          handleAddTreatmentRecordClick={handleAddTreatmentRecordClick}
          onEditTreatmentInfoClick={onEditTreatmentInfoClick}
          treatmentMethods={treatmentMethods}
          treatmentStatus={treatmentStatus}
          handleChangeTreatmentMethods={handleChangeTreatmentMethods}
          handleChangeTreatmentStatus={handleChangeTreatmentStatus}
          handleOpenAEModal={handleOpenAEModal}
          isOwner={userId === patient.Owner}
        />
      </Grid>
      <Grid
        item
        sm
        md
        sx={{ display: { xs: "none", sm: "block", md: "block" } }}
      >
        <TreatmentInfoContainer
          leftEyeTreatmentRecords={patient.leftEyeTreatmentRecords}
          rightEyeTreatmentRecords={patient.rightEyeTreatmentRecords}
          leftEyeDetails={patient.leftEyeDetails}
          rightEyeDetails={patient.rightEyeDetails}
          handleAddTreatmentRecordClick={handleAddTreatmentRecordClick}
          onEditTreatmentInfoClick={onEditTreatmentInfoClick}
          treatmentMethods={treatmentMethods}
          handleChangeTreatmentMethods={handleChangeTreatmentMethods}
          handleOpenAEModal={handleOpenAEModal}
          isOwner={userId === patient.Owner}
        />
      </Grid>
    </Box>
  );
};

export default PatientDetailPage;
