import {
  CreateUserProps,
  EditDataInput,
  UserRegisterProps,
  UserReturnProps,
  UserInstituteProps,
} from "../utils/types/services/user";
import { API_ROUTE_FULL_PATHS } from "../utils/constants/routes/apiPath";
import handleRequestErr from "../utils/helperFunction/handleRequestErr";
import notificationToUser from "../utils/helperFunction/notificationToUser";
import api from "./api";
import handleRequestFinally from "../utils/helperFunction/handleRequestFinally";
import { toast } from "react-toastify";
import i18n from "../i18n";
import _ from "lodash";

const VALIDATE_MSG = "singlePharse.validating";
const RESPONSE_MSG = "response.data.msg";
const STATUS_200 = 200;

export const userLogin = async (
  email: string,
  password: string
): Promise<UserReturnProps> => {
  let loginResult = null;
  try {
    loginResult = await api.post(API_ROUTE_FULL_PATHS.userLogin, {
      email,
      password,
    });

    if (loginResult.status === STATUS_200) {
      return {
        success: true,
        data: loginResult.data.data,
      };
    }
    notificationToUser({ msg: loginResult.data.msg });
    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: loginResult });
  }
};

export const userLogout = async (): Promise<UserReturnProps> => {
  let logoutResult = null;
  try {
    logoutResult = await api.post(API_ROUTE_FULL_PATHS.userLogout);

    if (logoutResult.status === STATUS_200)
      return {
        success: true,
        status: 401,
        data: logoutResult.data.data,
      };
    notificationToUser({ msg: logoutResult.data.msg });
    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: logoutResult });
  }
};

export const userGetData = async (): Promise<UserReturnProps> => {
  let userDataResult = null;
  try {
    userDataResult = await api.get(API_ROUTE_FULL_PATHS.userGetUserData);

    if (userDataResult.status === STATUS_200)
      return {
        success: true,
        data: userDataResult.data.data,
      };
    notificationToUser({ msg: userDataResult.data.msg });
    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: userDataResult });
  }
};

export const userEditData = async (
  props: EditDataInput
): Promise<UserReturnProps> => {
  let userDataResult = null;
  let instituteResult = null;
  try {
    const {
      firstName,
      lastName,
      nickName,
      instituteDetails,
      mobile,
      password,
      email,
    } = props;

    const tempDetails: any = [];

    instituteDetails.forEach((inst) => {
      tempDetails.push({
        instituteName: inst.instituteName,
        instituteType: inst.instituteType,
        instituteAddress: inst.instituteAddress,
        instituteDistrict: inst.instituteDistrict || "-",
        instituteMobile:
          inst.instituteMobile !== ""
            ? inst.instituteMobilePrefix + inst.instituteMobile
            : "",
        Sunday: inst.Sunday,
        Monday: inst.Monday,
        Tuesday: inst.Tuesday,
        Wednesday: inst.Wednesday,
        Thursday: inst.Thursday,
        Friday: inst.Friday,
        Saturday: inst.Saturday,
        hideHours: inst.hideHours,
      });
    });

    instituteResult = api.post(API_ROUTE_FULL_PATHS.userUpdateHcpInstitute, {
      firstName,
      lastName,
      preferredName: nickName,
      instituteDetails: tempDetails,
      email,
      mobile,
    });

    userDataResult = await api.post(API_ROUTE_FULL_PATHS.userEditUserData, {
      firstName,
      lastName,
      nickName,
      mobile,
      password,
    });

    if (userDataResult.status === STATUS_200) {
      toast.success("Save Success");
      return {
        success: true,
        data: userDataResult.data.data,
      };
    }
    notificationToUser({ msg: userDataResult.data.msg });
    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: userDataResult });
  }
};

export const uploadAvatar = async (
  avatarFormData: FormData | null
): Promise<UserReturnProps> => {
  let uploadAvatarResult = null;
  try {
    uploadAvatarResult = await api.post(
      API_ROUTE_FULL_PATHS.userUploadAvatar,
      avatarFormData
    );

    if (uploadAvatarResult.status === STATUS_200)
      return {
        success: true,
        data: uploadAvatarResult.data.data,
      };
    notificationToUser({ msg: uploadAvatarResult.data.msg });
    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: uploadAvatarResult });
  }
};

export const deleteAvatar = async (): Promise<UserReturnProps> => {
  let uploadAvatarResult = null;
  try {
    uploadAvatarResult = await api.post(API_ROUTE_FULL_PATHS.userDeleteAvatar);

    if (uploadAvatarResult.status === STATUS_200)
      return {
        success: true,
        data: uploadAvatarResult.data.data,
      };
    notificationToUser({ msg: uploadAvatarResult.data.msg });
    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: uploadAvatarResult });
  }
};

export const createUser = async (
  props: CreateUserProps
): Promise<UserReturnProps> => {
  let createUserResult = null;
  try {
    const {
      email,
      mobile,
      password,
      emailAuthKey,
      smsAuthKey,
      firstName,
      lastName,
      nickName,
      instituteDetails,
    } = props;

    createUserResult = await api.post(API_ROUTE_FULL_PATHS.userCreate, {
      email,
      mobile,
      password,
      emailAuthKey,
      smsAuthKey,
      firstName,
      lastName,
      nickName,
      instituteDetails,
    });

    if (createUserResult.status === STATUS_200) {
      toast.success(i18n.t("message.toast.createAccountSuccess") as string);
      return {
        success: true,
        data: createUserResult.data.data,
      };
    }

    notificationToUser({ msg: createUserResult.data.msg });
    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: createUserResult });
  }
};

export const getInstituteInfo = async (email: string): Promise<any> => {
  let sendRegisterCodeResult = null;
  try {
    sendRegisterCodeResult = await api.post(
      API_ROUTE_FULL_PATHS.userGetInstituteInfo,
      {
        email,
      }
    );

    if (sendRegisterCodeResult.status === STATUS_200)
      return {
        success: true,
        data: sendRegisterCodeResult.data.data,
      };
    notificationToUser({ msg: sendRegisterCodeResult.data.msg });
    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: sendRegisterCodeResult });
  }
};

export const sendEmailRegisterCode = async (
  email: string
): Promise<UserRegisterProps> => {
  let sendRegisterCodeResult = null;
  try {
    sendRegisterCodeResult = await api.post(
      API_ROUTE_FULL_PATHS.userSendEmailRegisterCode,
      {
        email,
      }
    );

    if (sendRegisterCodeResult.status === STATUS_200)
      return {
        success: true,
        data: sendRegisterCodeResult.data.data,
      };
    notificationToUser({ msg: sendRegisterCodeResult.data.msg });
    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: sendRegisterCodeResult });
  }
};

export const verifyEmailRegisterCode = async (
  email: string,
  code: string
): Promise<UserRegisterProps> => {
  let verifyRegisterCodeResult = null;
  toast.dismiss();
  const toastId = toast(i18n.t(VALIDATE_MSG) as string, {
    isLoading: true,
  });
  try {
    verifyRegisterCodeResult = await api.post(
      API_ROUTE_FULL_PATHS.userVerifyEmailRegisterCode,
      {
        email,
        code,
      }
    );

    if (verifyRegisterCodeResult.status === STATUS_200) {
      toast.dismiss();
      return {
        success: true,
        data: verifyRegisterCodeResult.data.data,
      };
    }

    notificationToUser({ msg: verifyRegisterCodeResult.data.msg });
    return {
      success: false,
    };
  } catch (err) {
    const errMsg = _.get(err, RESPONSE_MSG, null);
    if (typeof errMsg === "string") {
      toast.update(toastId, {
        isLoading: false,
        render: i18n.t(errMsg) as string,
        type: "error",
        autoClose: 5000,
      });
    }
    return handleRequestErr(err, false);
  } finally {
    handleRequestFinally({ result: verifyRegisterCodeResult });
  }
};

export const sendSMSRegisterCode = async (
  mobile: string
): Promise<UserRegisterProps> => {
  let sendRegisterCodeResult = null;
  try {
    sendRegisterCodeResult = await api.post(
      API_ROUTE_FULL_PATHS.userSendSMSRegisterCode,
      {
        mobile,
      }
    );

    if (sendRegisterCodeResult.status === STATUS_200)
      return {
        success: true,
        data: sendRegisterCodeResult.data.data,
      };
    notificationToUser({ msg: sendRegisterCodeResult.data.msg });
    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: sendRegisterCodeResult });
  }
};

export const verifySMSRegisterCode = async (
  mobile: string,
  code: string
): Promise<UserRegisterProps> => {
  let verifyRegisterCodeResult = null;
  toast.dismiss();
  const toastId = toast(i18n.t(VALIDATE_MSG) as string, {
    isLoading: true,
  });
  try {
    verifyRegisterCodeResult = await api.post(
      API_ROUTE_FULL_PATHS.userVerifySMSRegisterCode,
      {
        mobile,
        code,
      }
    );

    if (verifyRegisterCodeResult.status === STATUS_200) {
      toast.dismiss();
      return {
        success: true,
        data: verifyRegisterCodeResult.data.data,
      };
    }
    notificationToUser({ msg: verifyRegisterCodeResult.data.msg });
    return {
      success: false,
    };
  } catch (err) {
    const errMsg = _.get(err, RESPONSE_MSG, null);
    if (typeof errMsg === "string") {
      toast.update(toastId, {
        isLoading: false,
        render: i18n.t(errMsg) as string,
        type: "error",
        autoClose: 5000,
      });
    }
    return handleRequestErr(err, false);
  } finally {
    handleRequestFinally({ result: verifyRegisterCodeResult });
  }
};

export const sendEmailForgetPasswordCode = async (
  email: string
): Promise<UserRegisterProps> => {
  let sendRegisterCodeResult = null;
  try {
    sendRegisterCodeResult = await api.post(
      API_ROUTE_FULL_PATHS.userSendEmailForgotPasswordCode,
      {
        email,
      }
    );

    if (sendRegisterCodeResult.status === STATUS_200)
      return {
        success: true,
        data: sendRegisterCodeResult.data.data,
      };
    notificationToUser({ msg: sendRegisterCodeResult.data.msg });
    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: sendRegisterCodeResult });
  }
};

export const verifyEmailForgetPasswordCode = async (
  email: string,
  code: string
): Promise<UserRegisterProps> => {
  let verifyRegisterCodeResult = null;
  toast.dismiss();
  const toastId = toast(i18n.t(VALIDATE_MSG) as string, {
    isLoading: true,
  });
  try {
    verifyRegisterCodeResult = await api.post(
      API_ROUTE_FULL_PATHS.userVerifyEmailForgotPasswordCode,
      {
        email,
        code,
      }
    );

    if (verifyRegisterCodeResult.status === STATUS_200) {
      toast.dismiss();
      return {
        success: true,
        data: verifyRegisterCodeResult.data.data,
      };
    }

    notificationToUser({ msg: verifyRegisterCodeResult.data.msg });
    return {
      success: false,
    };
  } catch (err) {
    const errMsg = _.get(err, RESPONSE_MSG, null);
    if (typeof errMsg === "string") {
      toast.update(toastId, {
        isLoading: false,
        render: i18n.t(errMsg) as string,
        type: "error",
        autoClose: 5000,
      });
    }

    return handleRequestErr(err, false);
  } finally {
    handleRequestFinally({ result: verifyRegisterCodeResult });
  }
};

export const userChangePassword = async (
  newPassword: string,
  oldPassword: string
): Promise<UserReturnProps> => {
  let changePasswordResult = null;
  try {
    changePasswordResult = await api.post(
      API_ROUTE_FULL_PATHS.userChangePassword,
      {
        newPassword,
        oldPassword,
      }
    );

    if (changePasswordResult.status === STATUS_200) {
      toast.success("Change Password Success");
      return {
        success: true,
      };
    }
    notificationToUser({ msg: changePasswordResult.data.msg });
    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: changePasswordResult });
  }
};

export const userResetForgotPassword = async ({
  email,
  emailAuthKey,
  newPassword,
}: {
  email: string;
  emailAuthKey: string;
  newPassword: string;
}): Promise<UserReturnProps> => {
  let resetPasswordResult = null;
  try {
    resetPasswordResult = await api.post(
      API_ROUTE_FULL_PATHS.userResetForgotPassword,
      {
        email,
        emailAuthKey,
        newPassword,
      }
    );

    if (resetPasswordResult.status === STATUS_200) {
      toast.success(
        i18n.t("forgetPasswordPage.resetPasswordSuccess") as string
      );

      return {
        success: true,
      };
    }
    notificationToUser({ msg: resetPasswordResult.data.msg });
    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: resetPasswordResult });
  }
};

export const userGetPosologyReferenceImages = async (): Promise<{
  success: boolean;
  status?: number;
  data?: { name: string; imageUrl: string }[];
}> => {
  let userDataResult = null;
  try {
    userDataResult = await api.get(
      API_ROUTE_FULL_PATHS.userPosologyReferenceImages
    );

    if (userDataResult.status === STATUS_200)
      return {
        success: true,
        data: userDataResult.data.data,
      };

    throw new Error("Retry Will Apply");
  } catch (err) {
    handleRequestErr(err);
    throw err;
  } finally {
    handleRequestFinally({ result: userDataResult });
  }
};

export const userUpdatePSPStatus = async (
  status: boolean | null
): Promise<UserReturnProps> => {
  let userDataResult = null;
  try {
    userDataResult = await api.post(API_ROUTE_FULL_PATHS.userUpdatePSPStatus, {
      status,
    });

    if (userDataResult.status === STATUS_200) {
      toast.success("Save Success");
      return {
        success: true,
        data: userDataResult.data.data,
      };
    }
    notificationToUser({ msg: userDataResult.data.msg });
    return {
      success: false,
    };
  } catch (err) {
    return handleRequestErr(err);
  } finally {
    handleRequestFinally({ result: userDataResult });
  }
};
