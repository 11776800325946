import { useTranslation } from "react-i18next";
import { Grid, Typography, Link } from "@mui/material";

const PrivacyPolicy = () => {
  const { t } = useTranslation();
  const COLOR_BLACK = "common.black";
  return (
    <Grid item xs={12}>
      <Typography
        variant="subtitle1"
        fontWeight={"bold"}
        sx={{ textDecoration: "underline" }}
      >
        {t("policyText.privacypolicy.subTitle1")}
      </Typography>
      <Typography variant="subtitle2" paragraph>
        {t("policyText.privacypolicy.subParagraph1_1")}
        <b>{t("policyText.privacypolicy.subParagraph1_1_2")}</b>
        {t("policyText.privacypolicy.subParagraph1_1_3")}
      </Typography>
      <Typography variant="subtitle2" paragraph>
        {t("policyText.privacypolicy.subParagraph1_2")}
      </Typography>

      <Typography
        variant="subtitle1"
        fontWeight={"bold"}
        sx={{ textDecoration: "underline" }}
      >
        {t("policyText.privacypolicy.subTitle2")}
      </Typography>
      <Typography variant="subtitle2" paragraph>
        {t("policyText.privacypolicy.subParagraph2_1")}
        <Link
          href={t("policyText.privacypolicy.subParagraph2_2_Link")}
          target="_blank"
        >
          {t("policyText.privacypolicy.subParagraph2_2")}
        </Link>
        {t("policyText.privacypolicy.subParagraph2_3")}
      </Typography>
      <Typography variant="subtitle2" paragraph>
        {t("policyText.privacypolicy.subParagraph2_4")}
      </Typography>
      <Typography variant="subtitle2">
        {t("policyText.privacypolicy.subParagraph2_5")}
      </Typography>
      <Grid container flexDirection={"row"} sx={{ pl: "3%" }}>
        <Typography variant="subtitle2">1.</Typography>&nbsp;&nbsp;
        <Grid item xs>
          <Typography variant="subtitle2">
            <b>{t("policyText.privacypolicy.subParagraph2_6_1")}</b>
            {t("policyText.privacypolicy.subParagraph2_6_2")}
          </Typography>
        </Grid>
      </Grid>
      <Grid container flexDirection={"row"} sx={{ pl: "3%" }}>
        <Typography variant="subtitle2">2.</Typography>&nbsp;&nbsp;
        <Grid item xs>
          <Typography variant="subtitle2">
            <b>{t("policyText.privacypolicy.subParagraph2_a_1")}</b>
            {t("policyText.privacypolicy.subParagraph2_a_2")}
          </Typography>
        </Grid>
      </Grid>
      <Grid container flexDirection={"row"} sx={{ pl: "3%" }}>
        <Typography variant="subtitle2">3.</Typography>&nbsp;&nbsp;
        <Grid item xs>
          <Typography variant="subtitle2">
            <b>{t("policyText.privacypolicy.subParagraph2_7_1")}</b>
            {t("policyText.privacypolicy.subParagraph2_7_2")}
          </Typography>
        </Grid>
      </Grid>
      <Grid container flexDirection={"row"} sx={{ pl: "3%" }}>
        <Typography variant="subtitle2">4.</Typography>&nbsp;&nbsp;
        <Grid item xs>
          <Typography variant="subtitle2" paragraph>
            <b>{t("policyText.privacypolicy.subParagraph2_8_1")}</b>
            {t("policyText.privacypolicy.subParagraph2_8_2")}
          </Typography>
        </Grid>
      </Grid>

      <Typography
        variant="subtitle1"
        fontWeight={"bold"}
        sx={{ textDecoration: "underline" }}
      >
        {t("policyText.privacypolicy.subTitle3")}
      </Typography>
      <Typography variant="subtitle2" paragraph>
        {t("policyText.privacypolicy.subParagraph3_1")}
      </Typography>

      <Typography
        variant="subtitle1"
        fontWeight={"bold"}
        sx={{ textDecoration: "underline" }}
      >
        {t("policyText.privacypolicy.subTitle4")}
      </Typography>
      <Typography variant="subtitle2" paragraph>
        {t("policyText.privacypolicy.subParagraph4_1")}
      </Typography>
      <Grid container flexDirection={"row"}>
        <Grid
          item
          xs={4}
          sx={{
            borderWidth: 1,
            borderColor: COLOR_BLACK,
            borderStyle: "solid",
            padding: 1,
          }}
        >
          <Typography variant="subtitle2" fontWeight={"bold"}>
            {t("policyText.privacypolicy.subParagraph4_2_Grida_1")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            borderWidth: 1,
            borderLeftWidth: 0,
            borderRightWidth: 0,
            borderColor: COLOR_BLACK,
            borderStyle: "solid",
            padding: 1,
          }}
        >
          <Typography variant="subtitle2" fontWeight={"bold"}>
            {t("policyText.privacypolicy.subParagraph4_2_Gridb_1")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            borderWidth: 1,
            borderColor: COLOR_BLACK,
            borderStyle: "solid",
            padding: 1,
          }}
        >
          <Typography variant="subtitle2" fontWeight={"bold"}>
            {t("policyText.privacypolicy.subParagraph4_2_Gridc_1")}
          </Typography>
        </Grid>

        <Grid
          item
          xs={4}
          sx={{
            borderWidth: 1,
            borderTopWidth: 0,
            borderColor: COLOR_BLACK,
            borderStyle: "solid",
            padding: 1,
          }}
        >
          <Typography
            variant="subtitle2"
            fontWeight={"bold"}
            sx={{ textDecoration: "underline" }}
          >
            {t("policyText.privacypolicy.subParagraph4_2_Grid1_1")}
          </Typography>
          <Typography variant="subtitle2" paragraph>
            {t("policyText.privacypolicy.subParagraph4_2_Grid1_2")}
          </Typography>
          <Typography variant="subtitle2" paragraph>
            {t("policyText.privacypolicy.subParagraph4_2_Grid1_3")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            borderWidth: 1,
            borderTopWidth: 0,
            borderLeftWidth: 0,
            borderRightWidth: 0,
            borderColor: COLOR_BLACK,
            borderStyle: "solid",
            padding: 1,
          }}
        >
          <Typography variant="subtitle2">
            {t("policyText.privacypolicy.subParagraph4_2_Grid2_1")}
          </Typography>
          <Typography variant="subtitle2">
            {t("policyText.privacypolicy.subParagraph4_2_Grid2_2")}
          </Typography>
          <Typography variant="subtitle2">
            {t("policyText.privacypolicy.subParagraph4_2_Grid2_3")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            borderWidth: 1,
            borderTopWidth: 0,
            borderColor: COLOR_BLACK,
            borderStyle: "solid",
            padding: 1,
          }}
        >
          <Typography variant="subtitle2" paragraph>
            {t("policyText.privacypolicy.subParagraph4_2_Grid3_1")}
          </Typography>
        </Grid>

        <Grid
          item
          xs={4}
          sx={{
            borderWidth: 1,
            borderTopWidth: 0,
            borderColor: COLOR_BLACK,
            borderStyle: "solid",
            padding: 1,
          }}
        >
          <Typography
            variant="subtitle2"
            fontWeight={"bold"}
            sx={{ textDecoration: "underline" }}
          >
            {t("policyText.privacypolicy.subParagraph4_2_Gridd_1")}
          </Typography>
          <Typography variant="subtitle2" paragraph>
            {t("policyText.privacypolicy.subParagraph4_2_Gridd_2")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            borderWidth: 1,
            borderTopWidth: 0,
            borderLeftWidth: 0,
            borderRightWidth: 0,
            borderColor: COLOR_BLACK,
            borderStyle: "solid",
            padding: 1,
          }}
        >
          <Typography variant="subtitle2">
            {t("policyText.privacypolicy.subParagraph4_2_Gride_1")}
          </Typography>
          <Typography variant="subtitle2">
            {t("policyText.privacypolicy.subParagraph4_2_Gride_2")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            borderWidth: 1,
            borderTopWidth: 0,
            borderColor: COLOR_BLACK,
            borderStyle: "solid",
            padding: 1,
          }}
        >
          <Typography variant="subtitle2" paragraph>
            {t("policyText.privacypolicy.subParagraph4_2_Gridf_1")}
          </Typography>
          <Typography variant="subtitle2" paragraph>
            {t("policyText.privacypolicy.subParagraph4_2_Gridf_2")}
          </Typography>
          <Typography variant="subtitle2" paragraph>
            {t("policyText.privacypolicy.subParagraph4_2_Gridf_3")}
          </Typography>
        </Grid>

        <Grid
          item
          xs={4}
          sx={{
            borderWidth: 1,
            borderTopWidth: 0,
            borderColor: COLOR_BLACK,
            borderStyle: "solid",
            padding: 1,
          }}
        >
          <Typography
            variant="subtitle2"
            fontWeight={"bold"}
            sx={{ textDecoration: "underline" }}
          >
            {t("policyText.privacypolicy.subParagraph4_2_Gridg_1")}
          </Typography>
          <Typography variant="subtitle2" paragraph>
            {t("policyText.privacypolicy.subParagraph4_2_Gridg_2")}
          </Typography>
          <Typography variant="subtitle2" paragraph>
            {t("policyText.privacypolicy.subParagraph4_2_Gridg_3")}
          </Typography>
          <Typography variant="subtitle2" paragraph>
            {t("policyText.privacypolicy.subParagraph4_2_Gridg_4")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            borderWidth: 1,
            borderTopWidth: 0,
            borderLeftWidth: 0,
            borderRightWidth: 0,
            borderColor: COLOR_BLACK,
            borderStyle: "solid",
            padding: 1,
          }}
        >
          <Typography variant="subtitle2">
            {t("policyText.privacypolicy.subParagraph4_2_Gridh_1")}
          </Typography>
          <Typography variant="subtitle2">
            {t("policyText.privacypolicy.subParagraph4_2_Gridh_2")}
          </Typography>
          <Typography variant="subtitle2">
            {t("policyText.privacypolicy.subParagraph4_2_Gridh_3")}
          </Typography>
          <Typography variant="subtitle2">
            {t("policyText.privacypolicy.subParagraph4_2_Gridh_4")}
          </Typography>
          <Typography variant="subtitle2">
            {t("policyText.privacypolicy.subParagraph4_2_Gridh_5")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            borderWidth: 1,
            borderTopWidth: 0,
            borderColor: COLOR_BLACK,
            borderStyle: "solid",
            padding: 1,
          }}
        >
          <Typography variant="subtitle2" paragraph>
            {t("policyText.privacypolicy.subParagraph4_2_Gridi_1")}
          </Typography>
          <Typography variant="subtitle2" paragraph>
            {t("policyText.privacypolicy.subParagraph4_2_Gridi_2")}
          </Typography>
          <Typography variant="subtitle2" paragraph>
            {t("policyText.privacypolicy.subParagraph4_2_Gridi_3")}
          </Typography>
        </Grid>

        <Grid
          item
          xs={4}
          sx={{
            borderWidth: 1,
            borderTopWidth: 0,
            borderColor: COLOR_BLACK,
            borderStyle: "solid",
            padding: 1,
          }}
        >
          <Typography
            variant="subtitle2"
            fontWeight={"bold"}
            sx={{ textDecoration: "underline" }}
          >
            {t("policyText.privacypolicy.subParagraph4_2_Grid4_0")}
          </Typography>
          <Typography variant="subtitle2" paragraph>
            {t("policyText.privacypolicy.subParagraph4_2_Grid4_1")}
            <Link
              href={t("policyText.privacypolicy.subParagraph4_2_Grid4_2_Link")}
              target="_blank"
            >
              {t("policyText.privacypolicy.subParagraph4_2_Grid4_2")}
            </Link>
            {t("policyText.privacypolicy.subParagraph4_2_Grid4_3")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            borderWidth: 1,
            borderTopWidth: 0,
            borderLeftWidth: 0,
            borderRightWidth: 0,
            borderColor: COLOR_BLACK,
            borderStyle: "solid",
            padding: 1,
          }}
        >
          <Typography variant="subtitle2">
            {t("policyText.privacypolicy.subParagraph4_2_Grid5_1")}
          </Typography>
          <Typography variant="subtitle2">
            {t("policyText.privacypolicy.subParagraph4_2_Grid5_2")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            borderWidth: 1,
            borderTopWidth: 0,
            borderColor: COLOR_BLACK,
            borderStyle: "solid",
            padding: 1,
          }}
        >
          <Typography variant="subtitle2" paragraph>
            {t("policyText.privacypolicy.subParagraph4_2_Grid6_1")}
          </Typography>
        </Grid>

        <Grid
          item
          xs={4}
          sx={{
            borderWidth: 1,
            borderTopWidth: 0,
            borderColor: COLOR_BLACK,
            borderStyle: "solid",
            padding: 1,
          }}
        >
          <Typography
            variant="subtitle2"
            fontWeight={"bold"}
            sx={{ textDecoration: "underline" }}
          >
            {t("policyText.privacypolicy.subParagraph4_2_Grid7_1")}
          </Typography>
          <Typography variant="subtitle2" paragraph>
            {t("policyText.privacypolicy.subParagraph4_2_Grid7_2")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            borderWidth: 1,
            borderTopWidth: 0,
            borderLeftWidth: 0,
            borderRightWidth: 0,
            borderColor: COLOR_BLACK,
            borderStyle: "solid",
            padding: 1,
          }}
        >
          <Typography variant="subtitle2">
            {t("policyText.privacypolicy.subParagraph4_2_Grid8_1")}
          </Typography>
        </Grid>
        <Grid
          item
          xs={4}
          sx={{
            borderWidth: 1,
            borderTopWidth: 0,
            borderColor: COLOR_BLACK,
            borderStyle: "solid",
            padding: 1,
          }}
        >
          <Typography variant="subtitle2" paragraph>
            {t("policyText.privacypolicy.subParagraph4_2_Grid9_1")}
          </Typography>
          <Typography variant="subtitle2" paragraph>
            {t("policyText.privacypolicy.subParagraph4_2_Grid9_2")}
          </Typography>
        </Grid>
      </Grid>

      <Typography
        variant="subtitle1"
        fontWeight={"bold"}
        sx={{ textDecoration: "underline" }}
      >
        {t("policyText.privacypolicy.subTitle5")}
      </Typography>
      <Typography variant="subtitle2">
        {t("policyText.privacypolicy.subParagraph5_1")}
      </Typography>
      <Grid container flexDirection={"row"} sx={{ pl: "3%" }}>
        <Typography variant="subtitle2">1.</Typography>&nbsp;&nbsp;
        <Grid item xs>
          <Typography variant="subtitle2">
            {t("policyText.privacypolicy.subParagraph5_2")}
          </Typography>
        </Grid>
      </Grid>
      <Grid container flexDirection={"row"} sx={{ pl: "3%" }}>
        <Typography variant="subtitle2">2.</Typography>&nbsp;&nbsp;
        <Grid item xs>
          <Typography variant="subtitle2">
            {t("policyText.privacypolicy.subParagraph5_3")}
          </Typography>
        </Grid>
      </Grid>
      <Grid container flexDirection={"row"} sx={{ pl: "3%" }}>
        <Typography variant="subtitle2">3.</Typography>&nbsp;&nbsp;
        <Grid item xs>
          <Typography variant="subtitle2" paragraph>
            {t("policyText.privacypolicy.subParagraph5_4")}
          </Typography>
        </Grid>
      </Grid>

      <Typography
        variant="subtitle1"
        fontWeight={"bold"}
        sx={{ textDecoration: "underline" }}
      >
        {t("policyText.privacypolicy.subTitleA")}
      </Typography>
      <Typography variant="subtitle2" paragraph>
        {t("policyText.privacypolicy.subParagraphA_1")}
        {t("policyText.privacypolicy.subParagraphA_2")}
        <Link
          href={t("policyText.privacypolicy.subParagraphA_2_Link")}
          target="_blank"
        >
          {t("policyText.privacypolicy.subParagraphA_2_Link")}
        </Link>
        {t("policyText.privacypolicy.subParagraphA_3")}
      </Typography>

      <Typography
        variant="subtitle1"
        fontWeight={"bold"}
        sx={{ textDecoration: "underline" }}
      >
        {t("policyText.privacypolicy.subTitle6")}
      </Typography>
      <Typography variant="subtitle2" paragraph>
        {t("policyText.privacypolicy.subParagraph6_1")}
        {t("policyText.privacypolicy.subParagraph6_2")}
        <Link
          href={t("policyText.privacypolicy.subParagraph6_2_Link")}
          target="_blank"
        >
          {t("policyText.privacypolicy.subParagraph6_2_Link")}
        </Link>
        {t("policyText.privacypolicy.subParagraph6_3")}
      </Typography>

      <Typography
        variant="subtitle1"
        fontWeight={"bold"}
        sx={{ textDecoration: "underline" }}
      >
        {t("policyText.privacypolicy.subTitle7")}
      </Typography>
      <Typography variant="subtitle2" paragraph fontWeight={"bold"}>
        {t("policyText.privacypolicy.subParagraph7_1")}
      </Typography>
      <Grid container flexDirection={"row"} sx={{ pl: "3%" }}>
        <Typography variant="subtitle2" fontWeight={"bold"}>
          •
        </Typography>
        &nbsp;&nbsp;
        <Grid item xs>
          <Typography variant="subtitle2">
            {t("policyText.privacypolicy.subParagraph7_2")}
            <Link
              href={t("policyText.privacypolicy.subParagraph7_3_Link")}
              target="_blank"
            >
              {t("policyText.privacypolicy.subParagraph7_3")}
            </Link>
            {t("policyText.privacypolicy.subParagraph7_3_1")}
        </Typography>
        </Grid>
      </Grid>      
      <Grid container flexDirection={"row"} sx={{ pl: "3%" }}>
        <Typography variant="subtitle2" fontWeight={"bold"}>
          •
        </Typography>
        &nbsp;&nbsp;
        <Grid item xs>
          <Typography variant="subtitle2">
            {t("policyText.privacypolicy.subParagraph7_a")}
          </Typography>
        </Grid>
      </Grid>
      <Grid container flexDirection={"row"} sx={{ pl: "3%" }}>
        <Typography variant="subtitle2" fontWeight={"bold"}>
          •
        </Typography>
        &nbsp;&nbsp;
        <Grid item xs>
          <Typography variant="subtitle2">
            {t("policyText.privacypolicy.subParagraph7_6")}
          </Typography>
        </Grid>
      </Grid>
      <Grid container flexDirection={"row"} sx={{ pl: "3%" }}>
        <Typography variant="subtitle2" fontWeight={"bold"}>
          •
        </Typography>
        &nbsp;&nbsp;
        <Grid item xs>
          <Typography variant="subtitle2">
            {t("policyText.privacypolicy.subParagraph7_7")}
          </Typography>
        </Grid>
      </Grid>
      <Grid container flexDirection={"row"} sx={{ pl: "3%" }}>
        <Typography variant="subtitle2" fontWeight={"bold"}>
          •
        </Typography>
        &nbsp;&nbsp;
        <Grid item xs>
          <Typography variant="subtitle2">
            {t("policyText.privacypolicy.subParagraph7_8")}
          </Typography>
        </Grid>
      </Grid>
      <Grid container flexDirection={"row"} sx={{ pl: "3%" }}>
        <Typography variant="subtitle2" fontWeight={"bold"}>
          •
        </Typography>
        &nbsp;&nbsp;
        <Grid item xs>
          <Typography variant="subtitle2">
            {t("policyText.privacypolicy.subParagraph7_9")}
          </Typography>
        </Grid>
      </Grid>
      <Grid container flexDirection={"row"} sx={{ pl: "3%" }}>
        <Typography variant="subtitle2" fontWeight={"bold"}>
          •
        </Typography>
        &nbsp;&nbsp;
        <Grid item xs>
          <Typography variant="subtitle2">
            {t("policyText.privacypolicy.subParagraph7_10")}
          </Typography>
        </Grid>
      </Grid>
      <Grid container flexDirection={"row"} sx={{ pl: "3%" }}>
        <Typography variant="subtitle2" fontWeight={"bold"}>
          •
        </Typography>
        &nbsp;&nbsp;
        <Grid item xs>
          <Typography variant="subtitle2" paragraph>
            {t("policyText.privacypolicy.subParagraph7_11")}
          </Typography>
        </Grid>
      </Grid>

      <Typography
        variant="subtitle1"
        fontWeight={"bold"}
        sx={{ textDecoration: "underline" }}
      >
        {t("policyText.privacypolicy.subTitle8")}
      </Typography>
      <Typography variant="subtitle2" paragraph>
        {t("policyText.privacypolicy.subParagraph8_1")}
      </Typography>
      {/* <Typography variant="subtitle2" paragraph>
        {t("policyText.privacypolicy.subParagraph8_2")}
      </Typography> */}

      <Typography
        variant="subtitle1"
        fontWeight={"bold"}
        sx={{ textDecoration: "underline" }}
      >
        {t("policyText.privacypolicy.subTitle9")}
      </Typography>
      <Typography variant="subtitle2" paragraph>
        {t("policyText.privacypolicy.subParagraph9_1")}
      </Typography>

      <Typography
        variant="subtitle1"
        fontWeight={"bold"}
        sx={{ textDecoration: "underline" }}
      >
        {t("policyText.privacypolicy.subTitle10")}
      </Typography>
      <Typography variant="subtitle2" paragraph>
        {t("policyText.privacypolicy.subParagraph10_1")}
      </Typography>
      <Typography variant="subtitle2" paragraph>
        {t("policyText.privacypolicy.subParagraph10_2")}
        <Link
          href={t("policyText.privacypolicy.subParagraph10_3_Link")}
          target="_blank"
        >
          {t("policyText.privacypolicy.subParagraph10_3")}
        </Link>
        {t("policyText.privacypolicy.subParagraph10_4")}
      </Typography>
      <Typography variant="subtitle2" paragraph>
        {t("policyText.privacypolicy.subParagraph10_5")}
      </Typography>
      <Typography variant="subtitle2" paragraph>
        {t("policyText.privacypolicy.subParagraph10_6")}
        <Link
          href={t("policyText.privacypolicy.subParagraph10_7_Link")}
          target="_blank"
        >
          {t("policyText.privacypolicy.subParagraph10_7")}
        </Link>
        {t("policyText.privacypolicy.subParagraph10_8")}
      </Typography>
      <Typography variant="subtitle2" paragraph>
        {t("policyText.privacypolicy.subParagraph10_9")}
      </Typography>

      <Typography
        variant="subtitle1"
        fontWeight={"bold"}
        sx={{ textDecoration: "underline" }}
      >
        {t("policyText.privacypolicy.subTitle11")}
      </Typography>
      <Typography variant="subtitle2" paragraph>
        {t("policyText.privacypolicy.subParagraph11_1")}
      </Typography>
    </Grid>
  );
};

export default PrivacyPolicy;
