import { Grid, Typography, Box, Divider } from "@mui/material";
import { useTranslation } from "react-i18next";
import { PatientDetails } from "../../../../utils/types/services/patients";
import SideDrawer from "../../side-drawer";
import ReactGA from "react-ga4";
import { useEffect, useState } from "react";
import { ModalViewType } from "../../../../utils/constants/ga";
import BaseButton from "../../button/BaseButton";

interface ContactsModalProps {
  isOpen: boolean;
  handleClose: () => void;
  patient?: PatientDetails;
}

const ContactsModal = (props: ContactsModalProps) => {
  const { isOpen, handleClose, patient } = props;

  const headerText = "Caregiver Contacts";

  const handleCloseDrawer = () => {
    handleClose();
  };

  useEffect(() => {
    if (isOpen)
      ReactGA.send({
        hitType: "pageview",
        page: `${ModalViewType.PatientModal}/${headerText}`,
      });
  }, [isOpen]);

  return (
    <SideDrawer
      open={isOpen}
      onClose={handleCloseDrawer}
      headerText={headerText}
      handleCloseDrawerClick={handleCloseDrawer}
    >
      <Grid container flexDirection="row" sx={{ mt: 5, height: "100%" }}>
        <Grid item xs={12} sx={{ height: "90%" }}>
          <Box sx={{ mb: 3 }}>
            <Typography sx={{ color: "#0B41CD" }}>Caregiver 1</Typography>
            <Typography>{patient?.cgMob1}</Typography>
          </Box>
          <Box>
            <Typography sx={{ color: "#0B41CD" }}>Caregiver 2</Typography>
            <Typography>{patient?.cgMob2}</Typography>
          </Box>
        </Grid>
        <Grid
          container
          item
          xs={12}
          direction="row"
          alignItems="flex-end"
          justifyContent="flex-end"
        >
          <Divider sx={{ my: 1, width: "100%" }} />
          <BaseButton onClick={handleClose}>Done</BaseButton>
        </Grid>
      </Grid>
    </SideDrawer>
  );
};

export default ContactsModal;
